import React from 'react'
import Helmet from 'react-helmet'

const SecurityHeader = () => (
    <Helmet>
            {/* Sécurité des politiques de contenu */}
            <meta http-equiv="Content-Security-Policy"
                  content="default-src 'self'; script-src 'self'; style-src 'self' 'unsafe-inline'; img-src 'self' data:; connect-src 'self'; font-src 'self'; frame-ancestors 'none';"/>

            {/* Protection contre le clickjacking */}
            <meta http-equiv="X-Frame-Options" content="DENY"/>

            {/* Protection contre l'exécution de code JavaScript dans les en-têtes de réponse */}
            <meta http-equiv="X-XSS-Protection" content="1; mode=block"/>

            {/* Indication de l'utilisation de HTTPS uniquement */}
            <meta http-equiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains"/>

            {/* Prévention des redirections non sécurisées */}
            <meta http-equiv="X-Content-Type-Options" content="nosniff"/>

            {/* Prévention du préchargement de ressources de type font */}
            <meta http-equiv="X-Download-Options" content="noopen"/>

            {/* Prévention des attaques de type MIME sniffing */}
            <meta http-equiv="X-Permitted-Cross-Domain-Policies" content="none"/>
            <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js"
                    data-cbid="02367b38-5593-4b8f-a59a-0c3fed99fdc7" data-blockingmode="auto"
                    type="text/javascript"></script>
    </Helmet>
);

export default SecurityHeader;